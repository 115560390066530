import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import { Main } from './layoutStyles'

const Layout = ({pageContext, children, path}) => {
    const title = `Ash Betts`;
    const description = `Melbourne Based Freelance Web Developer & Designer`;
    const keywords = `Development, Web Design, Freelance, Melbourne`;
    const ogImage = "/static/img/favicon.png";

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: `en`,
                }}
                title={title}
                description={description}
            >
                <meta name={`description`} content={description} />
                <meta name={`keywords`} content={keywords} />

                <meta name={`og:title`} content={title} />
                <meta name={`og:description`} content={description} />
                <meta name={`og:type`} content={`website`} />
                <meta name={`og:image`} content={ogImage} />

                <meta name={`twitter:card`} content={`summary`} />
                <meta name={`twitter:title`} content={title} />
                <meta name={`twitter:description`} content={description} />

                <meta
                    name={`viewport`}
                    content={`width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0,viewport-fit=cover`}
                />

                <link rel={`alternate`} hrefLang={`x-default`} href={`https://ashbetts.com${path}`} />
                <link rel={`alternate`} hrefLang={`en`} href={`https://ashbetts.com${path}`} />
                <link rel={`canonical`} href={`https://ashbetts.com${path}`} />
                <meta name="theme-color" content="#000000" />
                <link href="https://fonts.googleapis.com/css?family=Major+Mono+Display&display=swap" rel="stylesheet"></link>

                <script type={`application/ld+json`}>
                    {`{
                        "url":"https://ashbetts.com",
                        "name":"${title}",
                        "description":"${description}",
                        "image":"${ogImage}",
                        "@context":"http://schema.org",
                        "@type":"WebSite"
                    }`}
                </script>
            </Helmet>
            <Header />
            <Main>
                {children}
            </Main>
            <Footer />
        </>
    )
}

export default Layout
