import React, { useState, useMemo, useEffect } from 'react'
import { HeaderContainer, Inner, LogoLink, Logo, MenuToggle, MenuWrapper, MenuLink, Menu } from './headerStyles'

const Header = () => {
    const [active, setActive] = useState(false);

    const options = useMemo(() => (
        [{'title': 'about me', 'link': '/'}, {'title': 'contact', 'link': '/contact/'}, {'title': 'privacy', 'link': '/privacy/'}]
    ), []);

    useEffect(() => {
        fetch(`/.netlify/functions/fetchShows?date=13032024`)
        .then(async (response) => {
            const res = await response.json();
            console.log(res);
        });
    });

    return useMemo(() => (        
        <HeaderContainer role={`navigation`} aria-label={`main-navigation`}>
            <Inner>
                <LogoLink to="/" title={`Ash Betts`} target={`_self`} onClick={() => setActive(false)}>
                    <Logo>
                        Ash Betts
                    </Logo>
                </LogoLink>
                <MenuToggle onClick={() => setActive(!active)} active={active}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" stroke="currentColor" fill="currentColor"><path d="M28 0C12.561 0 0 12.561 0 28s12.561 28 28 28 28-12.561 28-28S43.439 0 28 0zm0 54C13.663 54 2 42.336 2 28S13.663 2 28 2s26 11.664 26 26-11.663 26-26 26z"/><path d="M40 16H16a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2zm0 11H16a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2zm0 11H16a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2z"/></svg>
                </MenuToggle>
                <Menu active={active}>
                    <MenuWrapper>
                        {options.map((option, i) => (
                            <MenuLink to={option.link} onClick={(event) => {this.scrollUp()}}>
                                {option.title}
                            </MenuLink>
                        ))}
                    </MenuWrapper>
                </Menu>
            </Inner>
        </HeaderContainer>
    ), [active, options]);
}

export default Header
