import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby'

export const HeaderContainer = tw.header`w-full sticky top-0 left-0 z-50`;
export const Inner = tw.div`w-full p-4 lg:pl-8 bg-green flex items-center justify-between`;
export const LogoLink = tw(Link)`block lg:w-1/4 h-8`
export const Logo = tw.span`font-mono font-bold text-32 leading-8 text-black hover:text-red transition-colors leading-none`;
export const MenuToggle = styled.button`
    ${tw`flex items-center justify-end flex-wrap w-8 h-8 outline-none focus:outline-none transform duration-500`}
    ${({active}) => active ? tw`rotate-90 text-red` : tw`rotate-0`}
    svg {
        ${tw`w-full h-full overflow-visible`}
    }
`;
export const Menu = styled.div`
    ${tw`fixed top-0 right-0 mt-16 block transform duration-500`}
    ${({active}) => active ? tw`translate-x-0 bg-red` : tw`translate-x-64`}
`;
export const MenuWrapper = tw.nav`flex flex-col flex-wrap bg-green items-end justify-end w-64 border-t border-black transition-all`;
export const MenuLink = tw(Link)`w-full lg:w-auto font-thin font-mono text-black hover:text-red text-24 p-4 transition-all duration-500 text-right`