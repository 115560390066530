import React from 'react'
import { FooterContainer, FooterFlex, LinkIcon } from './footerStyles'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterFlex>
                <LinkIcon href={`https://play.google.com/store/apps/details?id=com.ashbetts.persona5rhelper`}>
                    <svg width="32" height="32" viewBox="0 0 481 513" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fill-rule="nonzero"><path d="M95.758 8.189c-20.032-11.072-43.552-10.816-63.456.16l233.28 215.2 78.368-78.368L95.758 8.189zM8.974 30.365C3.278 39.677.014 50.333.014 61.629v388.672c0 10.944 2.976 21.376 8.352 30.496l234.592-234.592L8.974 30.365zM447.854 202.525l-74.752-41.248-84 83.968 102.944 94.944 55.84-30.816c20.096-11.136 32.128-31.104 32.128-53.44-.032-22.336-12.032-42.304-32.16-53.408zM266.478 267.901L31.246 503.133c10.208 5.824 21.408 8.896 32.672 8.896 10.88 0 21.824-2.752 31.84-8.288l266.784-147.232-96.064-88.608z"/></g></svg>
                </LinkIcon>
                <LinkIcon href={`mailto:ashbettsdev@gmail.com`}>
                <svg width="32" height="32" viewBox="0 0 512 385" xmlns="http://www.w3.org/2000/svg"><path d="M464 .943H48c-26.469 0-48 21.531-48 48v288c0 26.469 21.531 48 48 48h416c26.469 0 48-21.531 48-48v-288c0-26.469-21.531-48-48-48zm0 32c2.174 0 4.242.45 6.132 1.238L256 219.771 41.867 34.181a15.927 15.927 0 016.132-1.238h416zm0 320H48c-8.828 0-16-7.172-16-16.001V67.989l213.515 185.047A15.983 15.983 0 00256 256.943c3.734 0 7.469-1.297 10.485-3.907L480 67.99v268.954c0 8.828-7.172 16-16 16z" fill="currentColor" fill-rule="nonzero"/></svg>
                </LinkIcon>
            </FooterFlex>
        </FooterContainer>
    )
}

export default Footer
