require('./static/css/style.css');
const React = require('react');
const Layout = require('./src/layout/layout').default;

exports.wrapPageElement = ({ element, props }) => (
    <Layout {...props}>{element}</Layout>
);

exports.onRouteUpdate = ({ location, prevLocation }) => {
    // console.log('new pathname', location)
    // console.log('old pathname', prevLocation ? prevLocation : null)
};
